$(function() {
  FontAwesomeConfig.searchPseudoElements = true;

  // Constants
  const MENU_SPEED = 200;
  bannerPadding();

  // Banner video
  $(window).on('resize', function() {
    bannerPadding();
  });

  // Navigation
  $('.navigation-link').on('click', () => {
    $('.navigation-link').find('.open').addClass('has-spinned');
    $('.navigation-link').toggleClass('is-active');
    $('body').toggleClass('is-active');
    $('.navigation').toggleClass('is-active');

    // menu
    if ($('.navigation-menu').hasClass('is-active')) {
      $('.navigation-menu').removeClass('is-active');
      $('.navigation-menu').addClass('is-closing');
      setTimeout(() => {
        $('.navigation-menu').removeClass('is-closing');
        $('.navigation-menu').addClass('is-closed');
        $('.navigation-menu-content').addClass('is-closed');
      }, MENU_SPEED);
    } else {
      $('.navigation-menu').addClass('is-active');
      $('.navigation-menu').removeClass('is-closed');
      $('.navigation-menu-content').removeClass('is-closed');
    }
  });

  // home
  if ($('.quicklinks-content').length) {
    var map = new GMaps({
      el: '#map',
      disableDefaultUI: true,
      lat: 44.0437723,
      lng: 4.642783,
      zoom: 8,
    });

    map.addStyle({
      styledMapName: 'Black & White',
      styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}],
      mapTypeId: 'style'
    });
    map.setStyle('style');
  }

  // Realisaties
  let slider = '';

  if (window.location.href.indexOf('realisaties') > -1) {
    $('.js-realisatie').click((e) => {
      const $realisatie = $(e.currentTarget);
      const title = $realisatie.find('[data-title]').html();
      const content = $realisatie.find('[data-content]').html();
      let images = '';

      $realisatie.find('[data-images]').find('p').each((index, val) => {
        images += `<li data-thumb="${$(val).html()}" data-src="${$(val).html()}"><img src="${$(val).html()}"></li>`;
      });

      $('.js-modal-images').html('');
      $('.js-modal-images').html(images);
      $('.js-modal-title').html(title);
      $('.js-modal-description').html(content);

      if (slider) {
        slider.destroy();
      }

      slider = $('#imageGallery').lightSlider({
        gallery: true,
        item: 1,
        loop: false,
        thumbItem: 5,
        slideMargin: 0,
        enableDrag: false,
        currentPagerPosition: 'left',
      });
    });
  }

  // Aanbod
  if (window.location.href.indexOf('aanbod') > -1 && window.location.href.indexOf('project') > -1) {
    $('#imageGallery').lightSlider({
      gallery: true,
      item: 1,
      loop: true,
      thumbItem: 5,
      slideMargin: 0,
      enableDrag: false,
      currentPagerPosition: 'left',
    });
  }

  if (window.location.href.indexOf('aanbod') > -1 && window.location.href.indexOf('project') === -1) {
    var map = new GMaps({
      el: '#map',
      disableDefaultUI: true,
      lat: 44.0437723,
      lng: 4.98,
      zoom: 10,
    });

    map.addStyle({
      styledMapName: 'Black & White',
      styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}],
      mapTypeId: 'style'
    });
    map.setStyle('style');

    var icon = {
      url: base_url + "/user/themes/ventouximmo/images/marker.png",
      scaledSize: new google.maps.Size(25, 40),
      origin: new google.maps.Point(0,0),
      anchor: new google.maps.Point(0, 0)
    };

    realisaties.forEach(function(realisatie) {
      var poster = base_url + '/' + Object.keys(realisatie.photos)[0];

      $.ajax({
        url: "https://maps.googleapis.com/maps/api/geocode/json?address=" + realisatie.location + "&key=AIzaSyAPX-URgMKHWRHlPz4GwLzl41fOFeMsyBg",
      }).done(function(api) {
        map.addMarker({
          icon,
          lat: api.results[0].geometry.location.lat,
          lng: api.results[0].geometry.location.lng,
          title: realisatie.title,
          infoWindow: {
            content: '<div style="font-family: sofia-pro,Helvetica,Arial,sans-serif;"><p style="font-size:16px;color:#262626;font-weight:bold;">' + realisatie.title + '</p>' + '<img style="margin-top:10px;width:150px;" src="' + poster + '"><div><a style="display:block;color:white;padding:10px;background-color:#b9994c;text-align:center;font-weight:bold;margin-top:5px;width:150px;" href="'+ base_url + '/aanbod/project?id=' + slugify(realisatie.title) +'-">Meer informatie</a></div></div>'
          },
          click: function(e) {
          }
        });
      });
    });
  }
});

function slugify(text) {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '-')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
}

function bannerPadding() {
  const $navigation = $('.navigation-link');
  const offset = $navigation[0].offsetLeft;
  const bannerWidth = $('.banner-container').outerWidth();
  $('.banner-container').css('padding-left', offset);
  $('.banner-container').css('width', 650 + offset);
}
